export const useTokens = defineStore('tokens', {
  state: () => ({
    access: null,
    refresh: null,
  }),
  actions: {
    setTokens(access, refresh) {
      this.access = access;
      this.refresh = refresh;
    },
    setAccessToken(access) {
      this.access = access;
    },
    setRefreshToken(refresh) {
      this.refresh = refresh;
    },
    reset() {
      this.access = null;
      this.refresh = null;
    }
  },
  getters: {
    getAccessToken: (state) => state.access,
    getRefreshToken: (state) => state.refresh,
    getTokens: (state) => {
      return {
        access: state.access,
        refresh: state.refresh,
      };
    },
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage()
  },
});