query GET_WHOAMI {
  whoami {
    email
    firstName
    hasVerifiedEmail
    id
    isActive
    lastLogin
    lastName
    phone
    role
  }
}