mutation VEHICLE_CREATE($make: String!, $model: String!, $color: String!, $licenseNumber: String!, $registeredState: String!) {
  createVehicle(make: $make, model: $model, color: $color, licenseNumber: $licenseNumber, registeredState: $registeredState) {
    success
    error
    vehicle {
      id,
      make,
      model,
      color,
      licenseNumber,
      registeredState,
    }
  }
}