<script setup>
defineProps(['error']);

const handleError = () => {
  clearError({ redirect: '/' });
}
</script>

<template>
  <main class="min-h-screen flex flex-col items-center justify-center bg-gray-50 dark:bg-gray-900 px-4">
    <NuxtImg class="h-18 w-18 mr-2" src="/images/spotsurferLogo.png" alt="Logo" />
    <div class="text-center">
      <div class="space-y-4">
        <h1 class="text-6xl font-bold text-gray-900 dark:text-white">
          {{ error?.statusCode || '404' }}
        </h1>
        <h2 class="text-xl font-semibold text-gray-700 dark:text-gray-300">
          {{ error?.message || 'Page not found' }}
        </h2>
        <p class="text-gray-500 dark:text-gray-400 max-w-lg mx-auto">
          {{
            error?.statusCode === 404
              ? "We couldn't find the page you're looking for. The page might have been removed or the URL might be incorrect."
              : "We encountered an unexpected error. Our team has been notified and is working to resolve the issue."
          }}
        </p>
        <div class="flex gap-4 justify-center mt-8">
          <Button
            @click="handleError"
          >
            Return Home
          </Button>
          <Button variant="secondary" @click="() => window.location.reload()">
            Try Again
          </Button>
        </div>
      </div>
    </div>
  </main>
</template>
