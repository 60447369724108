query GET_QUICKBOOK {
  quickBook {
    bookNowUrl
    tags
    lot {
      facility {
        name
        images {
          file
          isThumbnail
        }
      }
    }
  }
}