mutation UPDATE_LOT (
  $description: String
  $floor: Int
  $hasSpaceForBiggerVehicles: Boolean
  $id: UUID!
  $latitude: String
  $longitude: String
  $maxHeightSupported: Decimal
  $name: String
  $parkerInstructions: String
  $spots: Int
  $status: String
  $tagIds: [UUID]
) {
  updateLot(
    description: $description
    floor: $floor
    hasSpaceForBiggerVehicles: $hasSpaceForBiggerVehicles
    id: $id
    latitude: $latitude
    longitude: $longitude
    name: $name
    maxHeightSupported: $maxHeightSupported
    parkerInstructions: $parkerInstructions
    spots: $spots
    status: $status
    tagIds: $tagIds
  ) {
    error
    success
  }
}
