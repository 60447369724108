export const useUser = defineStore('useUser', {
  state: () => ({
    user: {},
    favorites: [],
    features: [],
    access_token: '',
    refresh_token: '',
    ownerId: '',
    parkerId: '',
  }),
  actions: {
    setUser(user) {
      this.user = user;
    },
    updateUser(user) {
      this.user.first_name = user.firstName;
      this.user.last_name = user.lastName;
      this.user.phone = user.phone;
      this.user.avatar = user?.avatar;
    },
    setFavorites(favorites) {
      this.favorites = favorites;
    },
    setFeatures(features) {
      this.features = features;
    },
    setOwnerId(ownerId) {
      this.ownerId = ownerId;
    },
    setParkerId(parkerId) {
      this.parkerId = parkerId;
    },
    setTokens(access_token, refresh_token) {
      this.access_token = access_token;
      this.refresh_token = refresh_token;
    },
  },
  getters: {
    getParkerId: (state) => state.parkerId,
    getUser: (state) => state.user,
    getUserFavorites: (state) => state.favorites,
    getUserFeatures: (state) => state.features
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage()
  },
})
