mutation EDIT_RESERVATION(
  $id: UUID!,
  $isRental: Boolean,
  $vehicleId: UUID
) {
  editReservation(
    id: $id,
    isRental: $isRental,
    vehicleId: $vehicleId
  ) {
    success
    error
  }
}
