query GET_PARKING_PACKS {
  facilityParkingPacks {
    items {
      id
      name
      description
      price
      displayOrder
      facility {
        name
        description
        images {
          name
          file
        }
        address{
          address1
          city
          state
          zipCode
        }
        amenities {
          name
          iconName
        }
      }

    }
  }
}