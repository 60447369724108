import validate from "/tmp/build_5eeea5be/node_modules/nuxt/dist/pages/runtime/validate.js";
import admin_45global from "/tmp/build_5eeea5be/middleware/admin.global.js";
import fetch_45facilities_45global from "/tmp/build_5eeea5be/middleware/fetchFacilities.global.js";
import user_45global from "/tmp/build_5eeea5be/middleware/user.global.js";
import manifest_45route_45rule from "/tmp/build_5eeea5be/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  admin_45global,
  fetch_45facilities_45global,
  user_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/tmp/build_5eeea5be/middleware/auth.js"),
  search: () => import("/tmp/build_5eeea5be/middleware/search.js")
}