export const useGraphQL = async (query, token, variables = {}) => {
  const config = useRuntimeConfig()
  const nuxtApp = useNuxtApp()

  // If any variable is a File, use FormData
  const hasFile = Object.values(variables).some(v => v instanceof File)
  const body = hasFile ? (() => {
    const form = new FormData()

    // Create operations object
    const operations = {
      query: query.loc.source.body,
      variables,
      operationName: query.definitions[0]?.name?.value
    }

    // Create map object and replace files with null
    const map = {}
    let fileIndex = 0

    Object.entries(variables).forEach(([key, value]) => {
      if (value instanceof File) {
        operations.variables[key] = null
        map[fileIndex] = [`variables.${key}`]
        form.append(String(fileIndex), value)
        fileIndex++
      }
    })

    form.append('operations', JSON.stringify(operations))
    form.append('map', JSON.stringify(map))

    return form
  })() : {
    query: query.loc.source.body,
    variables,
    operationName: query.definitions[0]?.name?.value
  }

  const headers = {
    ...(token && { 'Authorization': `Bearer ${token}` }),
    ...(!hasFile && { 'Content-Type': 'application/json' })
  }

  // If component is mounted (client-side), use $fetch directly
  if (nuxtApp.isHydrating || import.meta.server) {
    return await useAsyncData(
      `graphql-${query.definitions[0]?.name?.value || 'query'}`,
      () => $fetch(config.public.graphqlEndpoint, { method: 'POST', body, headers })
    )
  }

  // For client-side mutations/queries
  const response = await $fetch(config.public.graphqlEndpoint, {
    method: 'POST',
    body,
    headers
  })

  return { data: { value: response } }
}