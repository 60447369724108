import { jwtDecode } from "jwt-decode";

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    const { access } = useTokens();

    if (!access || !isValidToken(access)) {
      console.log('No token found or token is invalid');
      useLogout();
      return navigateTo('/login');
    }

    if (useUser().getUser.id) {
      if (to.fullPath.includes('/admin') && !useUser().getUser.is_staff) {
        return navigateTo('/')
      }
    }
  }
});

const isValidToken = (token) => {
  try {
    const decoded = jwtDecode(token);
    return decoded && decoded.exp > Date.now() / 1000;
  } catch (e) {
    return false;
  }
}
