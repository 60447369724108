query GET_LOT($id: UUID!) {
  lot(id: $id) {
    name
    description
    status
    parkerInstructions
    spots
    isApproved
    latitude
    longitude
    maxHeightSupported
    id
    hasSpaceForBiggerVehicles
    floor
    tags {
      id
      name
    }
    parkingSchedules {
      name
      appliesToDays
      price
      priority
      startDate
      endDate
      notes
      spots
      blackoutDates
      openingTime
      closingTime
    }
    facility {
      address {
        address1
        address2
        city
        id
        state
        zipCode
      }
      amenities {
        description
        iconName
        name
      }
      description
      displayOrder
      googleZoomLevel
      id
      images {
        displayOrder
        file
        id
        isThumbnail
        name
      }
      name
      maxBookingWindow
      markedAsSoldOut
      parkingPacks {
        allowUnlimitedParkings
        allowedDailyReservationsForUnlimitedParkings
        allowedParkings
        createdDatetime
        displayOrder
        description
        file
        iconName
        id
        name
        price
        status
        updatedDatetime
        weekdays
      }
      paidAmenities {
        id
        name
        price
        status
      }
    }
  }
}
