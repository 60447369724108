query GET_COLLECTIONS {
  collections {
    items {
      name
      facilities {
        id
        name
        paidAmenities {
          validityStartDate
          validityEndDate
          updatedDatetime
          status
          price
          name
          id
          iconName
          file
          description
          createdDatetime
        }
      }
    }
  }
}
