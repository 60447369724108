<script setup>
import { ToastProvider, Toaster } from '@/components/ui/toast'

const config = useRuntimeConfig().public;

useHead({
  htmlAttrs: {
    lang: 'en'
  },
  link: [
    {
      rel: 'icon',
      type: 'image/png',
      href: '/images/spotsurferLogo.png'
    }
  ]
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage />
    <ToastProvider>
      <Toaster />
    </ToastProvider>
  </NuxtLayout>
</template>

<style>
@media (max-width: 767px) {
  input, select, textarea {
    font-size: 16px !important;
  }
  .text-sm {
    font-size: 16px !important;
  }
}
</style>
