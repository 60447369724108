mutation UPLOAD_FACILITY_MEDIA (
	$facilityId: UUID!
	$files: [FileInput!]!
) {
  uploadFacilityMedia (
    facilityId: $facilityId
		files: $files
  )
  {
    error
    success
    facilityMedia {
      id
      name
      file
      isThumbnail
      displayOrder
    }
  }
}
