export const useParkerStore = defineStore('parkerStore', {
  state: () => ({
    parker: null,
    vehicleToEdit: null,
  }),
  actions: {
    setParker (parker) {
      this.parker = parker;
    },
    setParkerReservations (reservations) {
      this.parker.reservations = reservations;
    },
    setVehicleToEdit (vehicle) {
      this.vehicleToEdit = vehicle;
    },
    reset () {
      this.parker = null;
    }
  },
  getters: {
    getParker: (state) => state.parker,
    getParkerParkingPacks: (state) => state.parker?.parkerParkingPacks.filter((pack) => pack.order.status === 'PAYMENT_COMPLETE'),
    getParkerReservations: (state) =>
      state.parker?.reservations?.filter((reservation) => reservation.status === 'CONFIRMED'),
    getParkerSortedReservations: (state) => {
      const reservations =
        state.parker?.reservations?.filter((reservation) => reservation.status === 'CONFIRMED');
      return reservations?.sort((a, b) => new Date(b.checkoutDatetime) - new Date(a.checkoutDatetime));
    },
    getParkerCancelledReservations: (state) =>
      state.parker?.reservations.filter((reservation) => reservation.status === 'CANCELLED'),
    getParkerVehicles: (state) => state.parker?.vehicles.filter((vehicle) => vehicle.status === 'ACTIVE'),
    getParkerDeletedVehicles: (state) => state.parker?.vehicles.filter((vehicle) => vehicle.status === 'DELETED'),
  },
  persist: {
    storage: piniaPluginPersistedstate.localStorage()
  },
});
