query GET_CODES {
  codes {
    signupCodes {
      code
      description
    }
    freeVouchers {
      code
    }
    couponCodes {
      code
      description
    }
  }
}
