import * as contentful from 'contentful';

// This may need to be moved out of a plugin
// Due to how plugins work in Nuxt, this happens immediately
// when the app is loaded, regardless of the page.
export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig()
  const client = contentful.createClient({
    space: config.public.contentfulSpaceId,
    accessToken: config.public.contentfulAccessToken,
    environment: config.public.contentfulEnvironment,
  })

  const entries = await client.getEntries()
  entries.items.sort((a, b) => {
    const dateA = a.fields?.backDate ? new Date(a.fields.backDate) : new Date(a.sys.createdAt)
    const dateB = b.fields?.backDate ? new Date(b.fields.backDate) : new Date(b.sys.createdAt)
    return dateB - dateA
  })

  useBlog().setEntries(entries);
  nuxtApp.provide('contentfulClient', client)
})
