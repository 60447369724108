query GET_OWNER ($id: UUID!) {
  owner (id: $id) {
    name
    id
    facility {
      id
      name
      description
      maxBookingWindow
      markedAsSoldOut
      updatedDatetime
      status
      teamMembers {
        id
        email
        firstName
        lastName
        phone
        role
        status
        facilities {
          name
        }
      }
      images {
        id
        file
        displayOrder
        isThumbnail
        name
      }
      lots {
        id
        name
        spots
        status
        longitude
        latitude
        floor
        maxHeightSupported
        hasSpaceForBiggerVehicles
        parkerInstructions
        description
        isApproved
        tags {
          id
          name
        }
        facility {
          id
          name
        }
        parkingSchedules {
          id
          name
          appliesToDays
          price
          priceHourly
          priority
          startDate
          endDate
          notes
          spots
          blackoutDates
          openingTime
          closingTime
        }
      }
      address {
        address1
        address2
        city
        state
        zipCode
      }
      amenities {
        id
        description
        iconName
        name
      }
    }
  }
  reservations (ownerId: $id) {
    count
    items {
      checkoutDatetime
      checkinDatetime
      status
      isRental
      lot {
        name
      }
      order {
        finalAmount
      }
      parker {
        email
        phone
        user {
          firstName
          lastName
        }
      }
      vehicle {
        make
        licenseNumber
      }
    }
  }
}
