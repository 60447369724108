
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as faqsJ7wBnLl_45n5E_xtm7ouWwbDczQo9QwhgslZLH91h_45H_45YMeta } from "/tmp/build_5eeea5be/pages/faqs.vue?macro=true";
import { default as aboutJGpGWrX1fQzTgBevaxEgKqi8BE0U7P_45vx5Gh8gC3RS4Meta } from "/tmp/build_5eeea5be/pages/about.vue?macro=true";
import { default as indexhdYYC82wKQLxxSSa1PhF4qu_JW5JqcAUrFQ7At2JMrkMeta } from "/tmp/build_5eeea5be/pages/index.vue?macro=true";
import { default as loginpiLEy6OEjn3_Yy28dK_45gN5NawR9deuYom3fTFMOLt1MMeta } from "/tmp/build_5eeea5be/pages/login.vue?macro=true";
import { default as marketPlxSJGn3zUnvqP2mRsmNidwhd328bvE3WG1HUJ8lM2gMeta } from "/tmp/build_5eeea5be/pages/market.vue?macro=true";
import { default as searchZwyZhnmUpbKUrO5emLVLuIfQlpEGMJTOjQ5rLy0KuSsMeta } from "/tmp/build_5eeea5be/pages/search.vue?macro=true";
import { default as signupjW1uvt1WnV8pPx5_VUPmdjdBfIuQFBWwTD3vxFN4d5AMeta } from "/tmp/build_5eeea5be/pages/signup.vue?macro=true";
import { default as communityBbDcKcwoRqKTp_F30UzdcdrwsU8G0gnJ0T9sjkFQoCEMeta } from "/tmp/build_5eeea5be/pages/community.vue?macro=true";
import { default as contact_45us29cxLSpr0cGE_syG2jtOabHlhV4ai0utDCtwDMCspEAMeta } from "/tmp/build_5eeea5be/pages/contact-us.vue?macro=true";
import { default as indexg7CquPPTbuRYB8LXvir1yeH02V5n_3lk3WDGCZNRmIEMeta } from "/tmp/build_5eeea5be/pages/admin/index.vue?macro=true";
import { default as _91slug_93q0LK_458ms6JSR6xFcssJMAsElAjMouDYQmpE0evVS5UUMeta } from "/tmp/build_5eeea5be/pages/blog/[slug].vue?macro=true";
import { default as _91id_93SYv08YRibGw6sTZl0eXYjCi64jqUT6Ul8h5nxpIgbSIMeta } from "/tmp/build_5eeea5be/pages/orders/[id].vue?macro=true";
import { default as new_45password6qFtgmSPysUslDqM8HcIT_454XXAy71X_45x6VThXArWsHwMeta } from "/tmp/build_5eeea5be/pages/new-password.vue?macro=true";
import { default as owner_45signupOqIqcEITZdyjHm81ehopQV23EPrkyPc2xnxXsAGHARUMeta } from "/tmp/build_5eeea5be/pages/owner-signup.vue?macro=true";
import { default as parking_45packsMXFXWzoaEEskhC56OMsMvcDUTMiJYjcrm1tHlNlvwPkMeta } from "/tmp/build_5eeea5be/pages/parking-packs.vue?macro=true";
import { default as index_LLSqa_45VF0QlPpQE1B2EnisBH5DicI7e3GCEfGFvT9YMeta } from "/tmp/build_5eeea5be/pages/checkout/index.vue?macro=true";
import { default as reset_45passwordJ8fIY5ugB6Hda_45e21NGjwmwXBAlh0K2vA3uaFd_45sJ1sMeta } from "/tmp/build_5eeea5be/pages/reset-password.vue?macro=true";
import { default as _91id_93NAOQrmUoRLJUDO2Owq_TgvY5CTEOx3urgw3dYHokF7YMeta } from "/tmp/build_5eeea5be/pages/admin/lots/[id].vue?macro=true";
import { default as indexNk2QR3wDTcn6bJXa0rvvhF1v7H3ahNf__7JomqNuUykMeta } from "/tmp/build_5eeea5be/pages/dashboard/index.vue?macro=true";
import { default as news_45happeningsOXFlmFQfjCj4IM5UMtF7soF7jJJw0NwNgTmKQhviugAMeta } from "/tmp/build_5eeea5be/pages/news-happenings.vue?macro=true";
import { default as indexbABSwSoqBxHqhWSxly94BeG7Q0nyzLRVc18JsWto32sMeta } from "/tmp/build_5eeea5be/pages/admin/lots/index.vue?macro=true";
import { default as walleteTdipMAEx8IAc6WT2aVWZpIH0998_gmqtc5ysKJUonYMeta } from "/tmp/build_5eeea5be/pages/dashboard/wallet.vue?macro=true";
import { default as _91id_937iZGtNouzWX6wXbjNqT_dmWcsUHnnZJezuLoqig3BmUMeta } from "/tmp/build_5eeea5be/pages/admin/orders/[id].vue?macro=true";
import { default as supportLvDSELhdbMXkyyJKVMOk6viQqLqInZnE0cZv8uO4S50Meta } from "/tmp/build_5eeea5be/pages/dashboard/support.vue?macro=true";
import { default as _91id_93sgGF3aRINjxeQBi8aN3EEfoj79TltPb3qdvLxPy3_HYMeta } from "/tmp/build_5eeea5be/pages/reservations/[id].vue?macro=true";
import { default as indexxwh99O8GJaSyGFtFj9POnBrcuTZUUMv8FBjIkyELNHkMeta } from "/tmp/build_5eeea5be/pages/admin/orders/index.vue?macro=true";
import { default as _91id_93N6o3bmibMyqkJZ6AifmfPBMgT_45DnCGJrrOuR3cxSNhgMeta } from "/tmp/build_5eeea5be/pages/admin/parkers/[id].vue?macro=true";
import { default as add6wXIpU2Jj_tfpWs0IZciDaRaKpAzwcuUuHmqrKQeersMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/add.vue?macro=true";
import { default as settingskjjWb_xB8GKIcpCRAxWcG22Uc_79EZCUPy5dJWmkg6YMeta } from "/tmp/build_5eeea5be/pages/dashboard/settings.vue?macro=true";
import { default as addme4tAHAuYJnHsAqJV_45iIY7pE9KVfgYm3FPrMk3jAS5UMeta } from "/tmp/build_5eeea5be/pages/dashboard/team/add.vue?macro=true";
import { default as indexU8N3EpDBiW6km0aZFuFMNigu4miJBgpDJF7lKwgnbeEMeta } from "/tmp/build_5eeea5be/pages/admin/parkers/index.vue?macro=true";
import { default as editBMzBYz3cHUVbQ5GI7auMa_45B9TNpfVAwYUIFExtjpMagMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/edit.vue?macro=true";
import { default as indexXALlYY4YohiEoRVvh9OlbgY0SRYNawGZImuylggD4UQMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/index.vue?macro=true";
import { default as index08_45Xo4Z3gapWBzj5vBW5KwteVgf2vrOZlLVu4gansowMeta } from "/tmp/build_5eeea5be/pages/dashboard/team/index.vue?macro=true";
import { default as terms_45and_45conditionsZ37eG5sDyezVmI20QjtLuv_K_45ndjvA70SS5xp2YEa4kMeta } from "/tmp/build_5eeea5be/pages/terms-and-conditions.vue?macro=true";
import { default as _91id_93pYtVNTd1rDX1DbeU3ZOCukyGBrV4UTVdYIwNStnK5X8Meta } from "/tmp/build_5eeea5be/pages/admin/facilities/[id].vue?macro=true";
import { default as indexXCt7X9h4Bi40AwPqUBy1GepkYNQGRBty_45WFS2pJ5AK0Meta } from "/tmp/build_5eeea5be/pages/admin/affiliates/index.vue?macro=true";
import { default as index_s51BGAUQpu8TpjX4DhKluEipC9qmaOsc2dizQ6A_EAMeta } from "/tmp/build_5eeea5be/pages/admin/facilities/index.vue?macro=true";
import { default as indexhKMX7J3Lh6G7cNUzFlRXOKCbRKU5XNhfufXP2m3v1rMMeta } from "/tmp/build_5eeea5be/pages/admin/statements/index.vue?macro=true";
import { default as addLSCL9yCfAyfGCE_erD_45eatNWNxnVd_456vhxjWEoUrlV8Meta } from "/tmp/build_5eeea5be/pages/dashboard/vehicles/add.vue?macro=true";
import { default as _91id_93N_wuNLgOQ5dTJhU18_45Ea1CDhALta1z_uykecYGvRgbgMeta } from "/tmp/build_5eeea5be/pages/shop/confirmation/[id].vue?macro=true";
import { default as _91id_93gzWJpMdA5mqlC1AFGAedyiv986MDSoM0YFhgQtTZvzIMeta } from "/tmp/build_5eeea5be/pages/admin/reservations/[id].vue?macro=true";
import { default as editThPxfOKkoNpku4GpRq9zc35in61quz6wOZUaOzv4Nr0Meta } from "/tmp/build_5eeea5be/pages/dashboard/vehicles/edit.vue?macro=true";
import { default as account_45deletion_45requestZbF0Xm4ZHGg_454kP9m4FO0sj5DNV1oNIv2qoYop1O0UMMeta } from "/tmp/build_5eeea5be/pages/account-deletion-request.vue?macro=true";
import { default as indexD9QzC49GBBRJRKh24_ZWwDGTfZF_456BFwfPwYP2E6evcMeta } from "/tmp/build_5eeea5be/pages/admin/reservations/index.vue?macro=true";
import { default as addJKcQGC7CUCPTInI3bUDilSFMqe_8yhtFtthe70kACtMMeta } from "/tmp/build_5eeea5be/pages/dashboard/facilities/add.vue?macro=true";
import { default as indexI1vRjJotsU5JsUSyI6WMtf1zvxp_45XC2s0Uumn2TWROMMeta } from "/tmp/build_5eeea5be/pages/dashboard/vehicles/index.vue?macro=true";
import { default as edit2KXQn6dtMreD6WC8GnmYiVsdXJCuoWRQFkV_MnjWxp0Meta } from "/tmp/build_5eeea5be/pages/dashboard/facilities/edit.vue?macro=true";
import { default as indexrrQe3R_45CfJgY4b3C0oDNByZ10XqhxXMS0eS2wcJYnCIMeta } from "/tmp/build_5eeea5be/pages/dashboard/facilities/index.vue?macro=true";
import { default as addjbHCt5KXyHpfAn2v5MVPqFMnVJHS2K9KRrN42xAk36UMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/schedules/add.vue?macro=true";
import { default as edity_7z_v9uf_45_45NeuuBKCbz9868Ot4j2j6DlYUg1nFbKdEMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/schedules/edit.vue?macro=true";
import { default as utilsQmrjce5vgcOaTWUJRK75UcQgyMlkU2wL3snwHzGJbp4Meta } from "/tmp/build_5eeea5be/pages/dashboard/lots/schedules/utils.js?macro=true";
import { default as indexn5T1eQLbjI0bh1DxId20Iy72GPpQ8u76ZtEynnUIJ2IMeta } from "/tmp/build_5eeea5be/pages/dashboard/lots/schedules/index.vue?macro=true";
import { default as indexTADPFa6KOP6fO3GcUkNMwwG96FJ9eXzSqxBArSZrpjQMeta } from "/tmp/build_5eeea5be/pages/dashboard/facilities/media/index.vue?macro=true";
import { default as indexx9xH2QkoOYigjJByEmfhQRO7fZW4dQN94VRPvJ4bqPkMeta } from "/tmp/build_5eeea5be/pages/dashboard/facilities/amenities/index.vue?macro=true";
export default [
  {
    name: "faqs",
    path: "/faqs",
    component: () => import("/tmp/build_5eeea5be/pages/faqs.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/tmp/build_5eeea5be/pages/about.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexhdYYC82wKQLxxSSa1PhF4qu_JW5JqcAUrFQ7At2JMrkMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/tmp/build_5eeea5be/pages/login.vue")
  },
  {
    name: "market",
    path: "/market",
    component: () => import("/tmp/build_5eeea5be/pages/market.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: searchZwyZhnmUpbKUrO5emLVLuIfQlpEGMJTOjQ5rLy0KuSsMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/search.vue")
  },
  {
    name: "signup",
    path: "/signup",
    component: () => import("/tmp/build_5eeea5be/pages/signup.vue")
  },
  {
    name: "community",
    path: "/community",
    component: () => import("/tmp/build_5eeea5be/pages/community.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/tmp/build_5eeea5be/pages/contact-us.vue")
  },
  {
    name: "admin",
    path: "/admin",
    meta: indexg7CquPPTbuRYB8LXvir1yeH02V5n_3lk3WDGCZNRmIEMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/index.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/tmp/build_5eeea5be/pages/blog/[slug].vue")
  },
  {
    name: "orders-id",
    path: "/orders/:id()",
    component: () => import("/tmp/build_5eeea5be/pages/orders/[id].vue")
  },
  {
    name: "new-password",
    path: "/new-password",
    component: () => import("/tmp/build_5eeea5be/pages/new-password.vue")
  },
  {
    name: "owner-signup",
    path: "/owner-signup",
    component: () => import("/tmp/build_5eeea5be/pages/owner-signup.vue")
  },
  {
    name: "parking-packs",
    path: "/parking-packs",
    component: () => import("/tmp/build_5eeea5be/pages/parking-packs.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/tmp/build_5eeea5be/pages/checkout/index.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    component: () => import("/tmp/build_5eeea5be/pages/reset-password.vue")
  },
  {
    name: "admin-lots-id",
    path: "/admin/lots/:id()",
    meta: _91id_93NAOQrmUoRLJUDO2Owq_TgvY5CTEOx3urgw3dYHokF7YMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/lots/[id].vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexNk2QR3wDTcn6bJXa0rvvhF1v7H3ahNf__7JomqNuUykMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/index.vue")
  },
  {
    name: "news-happenings",
    path: "/news-happenings",
    component: () => import("/tmp/build_5eeea5be/pages/news-happenings.vue")
  },
  {
    name: "admin-lots",
    path: "/admin/lots",
    component: () => import("/tmp/build_5eeea5be/pages/admin/lots/index.vue")
  },
  {
    name: "dashboard-wallet",
    path: "/dashboard/wallet",
    meta: walleteTdipMAEx8IAc6WT2aVWZpIH0998_gmqtc5ysKJUonYMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/wallet.vue")
  },
  {
    name: "admin-orders-id",
    path: "/admin/orders/:id()",
    meta: _91id_937iZGtNouzWX6wXbjNqT_dmWcsUHnnZJezuLoqig3BmUMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/orders/[id].vue")
  },
  {
    name: "dashboard-support",
    path: "/dashboard/support",
    meta: supportLvDSELhdbMXkyyJKVMOk6viQqLqInZnE0cZv8uO4S50Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/support.vue")
  },
  {
    name: "reservations-id",
    path: "/reservations/:id()",
    component: () => import("/tmp/build_5eeea5be/pages/reservations/[id].vue")
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: indexxwh99O8GJaSyGFtFj9POnBrcuTZUUMv8FBjIkyELNHkMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/orders/index.vue")
  },
  {
    name: "admin-parkers-id",
    path: "/admin/parkers/:id()",
    meta: _91id_93N6o3bmibMyqkJZ6AifmfPBMgT_45DnCGJrrOuR3cxSNhgMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/parkers/[id].vue")
  },
  {
    name: "dashboard-lots-add",
    path: "/dashboard/lots/add",
    meta: add6wXIpU2Jj_tfpWs0IZciDaRaKpAzwcuUuHmqrKQeersMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/add.vue")
  },
  {
    name: "dashboard-settings",
    path: "/dashboard/settings",
    meta: settingskjjWb_xB8GKIcpCRAxWcG22Uc_79EZCUPy5dJWmkg6YMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/settings.vue")
  },
  {
    name: "dashboard-team-add",
    path: "/dashboard/team/add",
    meta: addme4tAHAuYJnHsAqJV_45iIY7pE9KVfgYm3FPrMk3jAS5UMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/team/add.vue")
  },
  {
    name: "admin-parkers",
    path: "/admin/parkers",
    meta: indexU8N3EpDBiW6km0aZFuFMNigu4miJBgpDJF7lKwgnbeEMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/parkers/index.vue")
  },
  {
    name: "dashboard-lots-edit",
    path: "/dashboard/lots/edit",
    meta: editBMzBYz3cHUVbQ5GI7auMa_45B9TNpfVAwYUIFExtjpMagMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/edit.vue")
  },
  {
    name: "dashboard-lots",
    path: "/dashboard/lots",
    meta: indexXALlYY4YohiEoRVvh9OlbgY0SRYNawGZImuylggD4UQMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/index.vue")
  },
  {
    name: "dashboard-team",
    path: "/dashboard/team",
    meta: index08_45Xo4Z3gapWBzj5vBW5KwteVgf2vrOZlLVu4gansowMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/team/index.vue")
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    component: () => import("/tmp/build_5eeea5be/pages/terms-and-conditions.vue")
  },
  {
    name: "admin-facilities-id",
    path: "/admin/facilities/:id()",
    meta: _91id_93pYtVNTd1rDX1DbeU3ZOCukyGBrV4UTVdYIwNStnK5X8Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/facilities/[id].vue")
  },
  {
    name: "admin-affiliates",
    path: "/admin/affiliates",
    meta: indexXCt7X9h4Bi40AwPqUBy1GepkYNQGRBty_45WFS2pJ5AK0Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/affiliates/index.vue")
  },
  {
    name: "admin-facilities",
    path: "/admin/facilities",
    meta: index_s51BGAUQpu8TpjX4DhKluEipC9qmaOsc2dizQ6A_EAMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/facilities/index.vue")
  },
  {
    name: "admin-statements",
    path: "/admin/statements",
    meta: indexhKMX7J3Lh6G7cNUzFlRXOKCbRKU5XNhfufXP2m3v1rMMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/statements/index.vue")
  },
  {
    name: "dashboard-vehicles-add",
    path: "/dashboard/vehicles/add",
    meta: addLSCL9yCfAyfGCE_erD_45eatNWNxnVd_456vhxjWEoUrlV8Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/vehicles/add.vue")
  },
  {
    name: "shop-confirmation-id",
    path: "/shop/confirmation/:id()",
    component: () => import("/tmp/build_5eeea5be/pages/shop/confirmation/[id].vue")
  },
  {
    name: "admin-reservations-id",
    path: "/admin/reservations/:id()",
    meta: _91id_93gzWJpMdA5mqlC1AFGAedyiv986MDSoM0YFhgQtTZvzIMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/reservations/[id].vue")
  },
  {
    name: "dashboard-vehicles-edit",
    path: "/dashboard/vehicles/edit",
    meta: editThPxfOKkoNpku4GpRq9zc35in61quz6wOZUaOzv4Nr0Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/vehicles/edit.vue")
  },
  {
    name: "account-deletion-request",
    path: "/account-deletion-request",
    component: () => import("/tmp/build_5eeea5be/pages/account-deletion-request.vue")
  },
  {
    name: "admin-reservations",
    path: "/admin/reservations",
    meta: indexD9QzC49GBBRJRKh24_ZWwDGTfZF_456BFwfPwYP2E6evcMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/admin/reservations/index.vue")
  },
  {
    name: "dashboard-facilities-add",
    path: "/dashboard/facilities/add",
    meta: addJKcQGC7CUCPTInI3bUDilSFMqe_8yhtFtthe70kACtMMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/facilities/add.vue")
  },
  {
    name: "dashboard-vehicles",
    path: "/dashboard/vehicles",
    meta: indexI1vRjJotsU5JsUSyI6WMtf1zvxp_45XC2s0Uumn2TWROMMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/vehicles/index.vue")
  },
  {
    name: "dashboard-facilities-edit",
    path: "/dashboard/facilities/edit",
    meta: edit2KXQn6dtMreD6WC8GnmYiVsdXJCuoWRQFkV_MnjWxp0Meta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/facilities/edit.vue")
  },
  {
    name: "dashboard-facilities",
    path: "/dashboard/facilities",
    meta: indexrrQe3R_45CfJgY4b3C0oDNByZ10XqhxXMS0eS2wcJYnCIMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/facilities/index.vue")
  },
  {
    name: "dashboard-lots-schedules-add",
    path: "/dashboard/lots/schedules/add",
    meta: addjbHCt5KXyHpfAn2v5MVPqFMnVJHS2K9KRrN42xAk36UMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/schedules/add.vue")
  },
  {
    name: "dashboard-lots-schedules-edit",
    path: "/dashboard/lots/schedules/edit",
    meta: edity_7z_v9uf_45_45NeuuBKCbz9868Ot4j2j6DlYUg1nFbKdEMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/schedules/edit.vue")
  },
  {
    name: "dashboard-lots-schedules-utils",
    path: "/dashboard/lots/schedules/utils",
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/schedules/utils.js")
  },
  {
    name: "dashboard-lots-schedules",
    path: "/dashboard/lots/schedules",
    meta: indexn5T1eQLbjI0bh1DxId20Iy72GPpQ8u76ZtEynnUIJ2IMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/lots/schedules/index.vue")
  },
  {
    name: "dashboard-facilities-media",
    path: "/dashboard/facilities/media",
    meta: indexTADPFa6KOP6fO3GcUkNMwwG96FJ9eXzSqxBArSZrpjQMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/facilities/media/index.vue")
  },
  {
    name: "dashboard-facilities-amenities",
    path: "/dashboard/facilities/amenities",
    meta: indexx9xH2QkoOYigjJByEmfhQRO7fZW4dQN94VRPvJ4bqPkMeta || {},
    component: () => import("/tmp/build_5eeea5be/pages/dashboard/facilities/amenities/index.vue")
  }
]