query GET_CREDIT_PACKS {
  creditPacks {
    items {
      credits
      id
      name
      price
      displayOrder
      description
      status
    }
  }
}