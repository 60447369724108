mutation CANCEL_RESERVATION(
  $id: UUID!,
  $cancellationOption: CancellationOptionEnum,
  $proceedForCancellation: Boolean!,
  $cancellationReason: String
) {
  cancelReservation(
    id: $id,
    cancellationOption: $cancellationOption,
    proceedForCancellation: $proceedForCancellation,
    cancellationReason: $cancellationReason
  ) {
    success
    error
    reservationStatus
    message
    cancellationOptions {
      amount
      description
      isRecommended
      type
    }
  }
}
