mutation USER_UPDATE($firstName: String!, $lastName: String!, $phone: String!, $avatar: Upload) {
  updateMyProfile(firstName: $firstName, lastName: $lastName, phone: $phone, avatar: $avatar) {
    success
    error
    user {
      firstName
      lastName
      phone
      avatar
    }
  }
}
