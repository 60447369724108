query GET_ORDERS ($endDate: Date, $startDate: Date, $facilityId: UUID, $status: String) {
  orders (
    endDate: $endDate,
    startDate: $startDate,
    facilityId: $facilityId,
    status: $status
  ) {
    items {
      id
      amount
      codeType
      finalAmount
      status
      appliedOn
      createdDatetime
      receipt
      updatedDatetime
      parker {
        id
        user {
          firstName
          lastName
          signupCode
        }
      }
    }
  }
}
