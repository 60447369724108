import { GET_FACILITIES } from '@/queries';

// An Access token shouldn't be required for Facilities
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    if(to.fullPath.includes('/admin')) {
      if (useAdminDashboard().facilities.length > 0) {
        return;
      }

      const fetchFacilities = async () => {
        const { data } = await useGraphQL(
          GET_FACILITIES,
          useTokens().access
        );

        const facilities = data?.value?.data?.facilities.items;
        useAdminDashboard().setFacilities(facilities);
      }

      fetchFacilities()
    }
  }
});
