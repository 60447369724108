query GET_FACILITIES {
  facilities {
    count
    items {
      id
      name
      updatedDatetime
      maxBookingWindow
      images {
        isThumbnail
        file
        name
      }
      lots {
        id
      }
    }
  }
}
