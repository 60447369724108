query GET_FACILITY($id: UUID!) {
    facility(id: $id) {
    description
    name
    id
    updatedDatetime
    teamMembers {
      id
      email
      firstName
      lastName
      phone
      role
      status
      facilities {
        name
      }
    }
    lots {
      id
      name
      description
      spots
      updatedDatetime
      isApproved
      hasSpaceForBiggerVehicles
      latitude
      longitude
      maxHeightSupported
      parkerInstructions
      status
      floor
      approvalNotes
      createdDatetime
    }
  }
}
