query GET_RESERVATION($id: UUID!) {
  reservation(id: $id) {
    checkinDatetime
    checkoutDatetime
    id
    status
    cancellationDatetime
    isRental
    signupCode
    order {
      id
    }
    parker {
      id
      phone
      user {
        firstName
        lastName
        phone
      }
      email
      phone
    }
    lot {
      name
      parkerInstructions
      facility {
        images {
          file
          isThumbnail
        }
        address {
          address1
          address2
          city
          state
          zipCode
        }
      }
    }
    vehicle {
      licenseNumber
      make
      model
    }
  }
}
