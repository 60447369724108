query GET_ORDER ($id: UUID!) {
  order (id: $id) {
    id
    amount
    finalAmount
    receipt
    status
    appliedOn
    appliedOnItem
    itemsMetadata
    parker {
      id,
      email,
      phone
      user {
        id
        firstName
        lastName
      }
    }
  }
}
