import { format, startOfYear } from 'date-fns';

export const useAdminDashboard = defineStore('adminDashboard', {
  state: () => ({
    endDate: format(new Date(), 'yyyy-MM-dd'),
    startDate: format(startOfYear(new Date()), 'yyyy-MM-dd'),
    facility: null,
    facilities: [],
    codes: null,
  }),
  actions: {
    setQuery(endDate, startDate, facility) {
      this.endDate = endDate;
      this.startDate = startDate;
      this.facility = facility;
    },
    setCodes(codes) {
      this.codes = codes;
    },
    setFacilities(facilities) {
      this.facilities = facilities;
    },
  },
  getters: {
    getActiveFacility: (state) => state.facility,
    getFacilityById: (state) => {
      return (id) => state?.facilities?.find((facility) => facility.id === id)
    },
    signupCodes: (state) => state.codes?.signupCodes,
    getQuery: (state) => {
      if (state.facility?.id) {
        return `?facility=${state.facility.id}&startDate=${state.startDate}&endDate=${state.endDate}`
      }
      return `?startDate=${state.startDate}&endDate=${state.endDate}`
    }
  }
});
