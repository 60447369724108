query GET_PARKER ($id: UUID!) {
  parker(id: $id) {
    email
    id
    phone
    user {
      avatar
      firstName
      lastName
      role
      signupCode
      hasRedeemedSignupCode
    }
    freeVouchers {
      id
      code
      isRedeemed
      redeemedOnDatetime
    }
    parkerCreditPacks {
      id
    }
    parkerParkingPacks {
      id
      order {
        status
      }
      parkingsUsed
      parkingPack {
        allowUnlimitedParkings
        allowedDailyReservationsForUnlimitedParkings
        allowedParkings
        createdDatetime
        description
        displayOrder
        name
        status
        weekdays
        facility {
          id
        }
      }
    }
    vehicles {
      color
      id
      licenseNumber
      make
      model
      registeredState
      status
      type
      frontProfile
      rearProfile
    },
    reservations {
      cancellationDatetime
      id
      checkinDatetime
      checkoutDatetime
      status
      updatedDatetime
      isRental
      vehicle {
        licenseNumber
        make
        model
      }
      lot {
        parkerInstructions
        name
        facility{
          images {
            file
          }
        address{
          address1
          address2
          city
          state
          zipCode
        }
      }
      }
      order {
        id
        amount
        receipt
        finalAmount
        itemsMetadata
      }
    }
    wallet {
      credits
      id
    }
  }
}
