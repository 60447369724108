query GET_ANALYTICS ($endDate: Date, $startDate: Date, $facilityId: UUID) {
  analytics(endDate: $endDate, startDate: $startDate, facilityId: $facilityId) {
    timePeriod
    cancelationRate
    totalParkers
    totalOrders
    totalCreditPacksSold
    totalCancelations
    totalActiveUsers
    totalActiveSpots
    totalActiveFacilities
    totalParkingPacksSold
    totalRegisteredOwners
    totalRegisteredParkers
    totalReservations
    revenueFromReservations
    revenueFromParkingPacks
    revenueFromCreditPacks
    endDate
    startDate
  }
}