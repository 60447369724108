query SEARCH($checkinDate: String!, $checkoutDate: String, $facility: UUID) {
  search(checkinDate: $checkinDate, checkoutDate: $checkoutDate, facility: $facility) {
    checkinDate
    checkoutDate
    isAvailable
    price
    averagePrice
    thumbnail
    availability {
      availableOnDates
      unavailableOnDates
    }
    lot {
      id
      name
      description
      parkerInstructions
      maxHeightSupported
      latitude
      longitude
      facility {
        name
        id
        address {
          address1
          address2
          city
          id
          state
          zipCode
        }
        status
        amenities {
          description
          name
        }
        description
        googleZoomLevel
        markedAsSoldOut
        maxBookingWindow
        paidAmenities {
          name
          price
          status
        }
        images {
          isThumbnail
          name
          file
          displayOrder
        }
      }
    }
    tags
  }
}

# query MyQuery {
#   search(
#     checkinDate: "03/20/2025"
#     checkoutDate: ""
#     facility: "df621f5b-5cbb-42ce-8cdf-876571eda80c"
#   ) {
#     checkinDate
#     checkoutDate
#     isAvailable
#     price
#     averagePrice
#     thumbnail
#     availability {
#       availableOnDates
#       unavailableOnDates
#     }
#     tags
#   }
# }