query GET_USERS($startDate: Date, $endDate: Date, $signupCode: String) {
  users(startDate: $startDate, endDate: $endDate, signupCode: $signupCode) {
    count
    items {
      id
      email
      firstName
      lastName
      phone
      signupCode
    }
  }
}
