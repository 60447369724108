mutation UPDATE_PARKING_SCHEDULE (
  $id: UUID!
  $blackoutDates: [Date]
  $closingTime: Time!
  $endDate: Date!
  $name: String
  $notes: String
  $openingTime: Time!
  $price: Decimal!
  $priceHourly: Decimal!
  $priority: Int
  $spots: Int
  $startDate: Date!
  $surgeFactor: Decimal
  $appliesToDays: [String]!
  ) {
  updateParkingSchedule(
    id: $id
    blackoutDates: $blackoutDates
    closingTime: $closingTime
    endDate: $endDate 
    name: $name
    notes: $notes
    openingTime: $openingTime
    price: $price
    priceHourly: $priceHourly 
    priority: $priority
    spots: $spots 
    startDate: $startDate
    surgeFactor: $surgeFactor
    appliesToDays: $appliesToDays
  ) {
    success
    error
  }
}
