query GET_FACILITY_PARKING_PACK($id: UUID!) {
  facilityParkingPack(id: $id) {
    allowedParkings
    allowedDailyReservationsForUnlimitedParkings
    allowUnlimitedParkings
    description
    name
    price
  }
}
