mutation ADD_LOT (
  $description: String,
  $facilityId: UUID!,
  $floor: Int = 1,
  $hasSpaceForBiggerVehicles: Boolean,
  $latitude: String!,
  $longitude: String!,
  $maxHeightSupported: Float,
  $name: String!,
  $parkerInstructions: String,
  $spots: Int!,
  $tagIds: [UUID]
) {
  addLot(
    description: $description,
    facilityId: $facilityId,
    floor: $floor,
    hasSpaceForBiggerVehicles: $hasSpaceForBiggerVehicles,
    latitude: $latitude,
    longitude: $longitude,
    maxHeightSupported: $maxHeightSupported,
    name: $name,
    parkerInstructions: $parkerInstructions,
    spots: $spots,
    tagIds: $tagIds
  ) {
    success
    error
  }
}
