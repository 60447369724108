query GET_STATEMENT($endDate: Date!, $startDate: Date!, $facilityId: UUID!) {
  facilityStatement(
    endDate: $endDate
    startDate: $startDate
    facilityId: $facilityId
  ) {
    name
    revenueSplitDescription
    totalFees
    totalRevenue
    totalRevenueAfterFees
    totalRevenueSplit
    feesDescription
    endDate
    startDate
    timePeriod
    reservations {
      amount
      carMake
      carModel
      checkinDate
      checkoutDate
      fees
      guestName
      id
      licensePlate
      revenueSplit
      total
      parkingPack {
        name
      }
      signupCode
    }
  }
}
