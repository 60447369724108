query GET_PARKERS ($endDate: Date, $startDate: Date){
  parkers (endDate: $endDate, startDate: $startDate) {
    items {
      email
      id
      phone
      user {
        firstName
        lastName
        role
      }
    }
  }
}