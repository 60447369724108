mutation ADD_FACILITY (
  $name: String!,
  $description: String,
  $maxBookingWindow: Int,
  $address1: String!,
  $address2: String,
  $city: String!,
  $state: String!,
  $zipCode: String!,
  $amenities: [ID],
) {
  addFacility(
    name: $name,
    description: $description,
    maxBookingWindow: $maxBookingWindow,
    address1: $address1,
    address2: $address2,
    city: $city,
    state: $state,
    zipCode: $zipCode,
    amenities: $amenities,
  ) {
    success
    error
    facility {
      id
      name
    }
  }
}
