import { GET_PARKER } from '@/queries';

export const useParker = async (parkerId) => {
  if (!useUser().getUser.id) return;

  try {
    const fetchParker = async () => {
      const { data } = await useGraphQL(
        GET_PARKER,
        useTokens().access,
        {
          id: parkerId
        }
      );

      useParkerStore().setParker(data?.value?.data?.parker);
    }

    fetchParker()
  } catch (error) {
    console.log('❌ Error getting parker', error);
  }
}
