mutation UPDATE_VEHICLE (
  $id: ID!,
  $make: String!,
  $model: String!,
  $color: String!,
  $licenseNumber: String!,
  $registeredState: String!,
  $frontProfile: Upload,
  $rearProfile: Upload
) {
  updateVehicle(
    id: $id,
    make: $make,
    model: $model,
    color: $color,
    licenseNumber: $licenseNumber,
    registeredState: $registeredState,
    frontProfile: $frontProfile,
    rearProfile: $rearProfile
  ) {
    success
    error
    vehicle {
      id,
      make,
      model,
      color,
      licenseNumber,
      registeredState,
    }
  }
}
