export const useBlog = defineStore('blog', {
  state: () => ({
    assets: [],
    entries: null,
  }),
  actions: {
    setEntries(entries) {
      this.entries = entries;
    }
  },
  getters: {
    posts: (state) => state.entries?.items?.filter((item) => item?.sys?.contentType?.sys?.id === 'blogPage') || [],
  },
});
