mutation ADD_TEAM_MEMBER (
  $email: String!,
  $facilityIds: [UUID]!,
  $firstName: String!,
  $lastName: String,
  $phone: String!,
  $role: String!
) {
  addTeamMember(
    email: $email,
    facilityIds: $facilityIds,
    firstName: $firstName,
    lastName: $lastName,
    phone: $phone,
    role: $role
  ) {
    success
    error
    teamMember {
      phone
      role
      firstName
      lastName
      email
      phone
      id
    }
  }
}
