export default defineNuxtRouteMiddleware(() => {
  if (import.meta.client) {
    if (!useUser().getUser.id && useParkerStore().getParker?.id) {
      useParkerStore().reset();
    }

    if (useUser().getUser.role === 'PARKER' && !useParkerStore().getParker) {
      useParker(useUser().getParkerId);
    }
  }
})
