query GET_RESERVATIONS ($endDate: Date, $startDate: Date, $facilityId: UUID, $status: String, $signupCode: String) {
  reservations (
    endDate: $endDate,
    startDate: $startDate,
    facilityId: $facilityId,
    status: $status,
    signupCode: $signupCode
  ) {
    count
    items {
      id
      checkinDatetime
      checkoutDatetime
      status
      signupCode
      parker {
        id
        user {
          firstName
          lastName
        }
        email
        phone
      }
      lot {
        name
      }
      vehicle {
        licenseNumber
      }
    }
  }
}
